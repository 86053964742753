var GPM_calendar = function (selector) {

    var calendar = this;

    this.objCalendar = selector;
    this.enablePopover = true;

    this.bindEvents = function () {
        calendar.initCalendario();
    };

    this.initCalendario = function () {
        /* initialize the calendar */

        calendar.objCalendar.fullCalendar({
            defaultView: 'timelineDay',
            editable: true,
            droppable: true,
            slotDuration: "00:30:00",
            minTime: "08:00:00",
            maxTime: "19:00:00",
            header: {
                left: 'prev, next',
                center: 'title',
                right: 'timelineDay,timelineWeek,timelineMonth'
            },
            buttonHtml: {
                prev: '<i class="ace-icon fa fa-chevron-left"></i>',
                next: '<i class="ace-icon fa fa-chevron-right"></i>'
            },
            buttonText: {
                timelineDay: 'Giorno',
                timelineWeek: 'Settimana',
                timelineMonth: 'Mese'
            },
            resourceLabelText: 'MACCHINARI',
            resources:function (callback) {
                $.get(app.baseUrl + '/calendario/resources', function (data) {
                    calendar.calendar_resources = data;
                    callback(data);
                });
            },
            events: {
                url: app.baseUrl + '/calendario/data',
                type: 'GET',
                data:
                    function () {
                        return {
                            visualizzazione: calendar.objCalendar.fullCalendar('getView').type
                        };
                    },
                error: function () {
                    app.error('Errore recuperando gli eventi del calendario');
                },
            },
            viewRender: function() {
                calendar.objCalendar.fullCalendar('removeEvents');
                calendar.objCalendar.fullCalendar('refetchEvents');
            },
            eventReceive: function(event) {
                if(event.resourceId === null) {
                    calendar.objCalendar.fullCalendar('removeEvents');
                    calendar.objCalendar.fullCalendar('refetchEvents');
                    return;
                }

                $.post(app.baseUrl + "/calendario/crea", {
                    id_subject: event.id,
                    id_risorsa: event.resourceId,
                    start: event.start.format("YYYY-MM-DD HH:mm:ss"),
                    end: event.start.format("YYYY-MM-DD HH:mm:ss")
                })
                .done(function (data) {
                    if(data.response) {
                        calendar.objCalendar.fullCalendar('removeEvents');
                        calendar.objCalendar.fullCalendar('refetchEvents');
                    } else {
                        app.warning("", data.message);
                    }
                })
                .fail(function () {
                    app.error("", "Errore Ajax!");
                });
            },
            eventRender: function (event, element, a) {
                element.find('.fc-title').append("<br/>" + event.description);
            },
            eventDragStart: function () {
                calendar.enablePopover = false;
            },
            eventDragStop: function (data, evento, ui, resourceId) {
                calendar.enablePopover = true;
            },
            eventDrop: function(event, delta, revertFunc, jsEvent, ui, view){

            },
            eventResize: function(event, delta, revertFunc){

            },
            eventClick: function (event, jsEvent, view) {

            }
        });
    };
    calendar.bindEvents();
};
