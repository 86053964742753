var scadenziario = function () {
    var eventsFullDay = false;
    var scadenziario = this;
    this.isScadenziarioPagamenti = 0;
    this.preferenceType = 'agendaWeek';

    /*this.popola = function () {
        $.get("scadenziario?isScadenziarioPagamenti=" + this.isScadenziarioPagamenti, function (data) {
            $.each(data.message, function () {
                $('[data-interaction="scadenziario"]').fullCalendar('renderEvent',
                    {
                        id: this.id,
                        title: this.evento,
                        start: this.data,
                        end: this.data,
                        color: this.colore,
                        allDay: eventsFullDay
                    }
                    , true);
            });
        });
    };*/

    this.init = function (isScadenziarioPagamenti) {
        isScadenziarioPagamenti = isScadenziarioPagamenti || 0;
        scadenziario.isScadenziarioPagamenti = isScadenziarioPagamenti;

        /*if (isScadenziarioPagamenti == 1)
            scadenziario.preferenceType = "month";
        */
        var date = new Date();
        var d = date.getDate();
        var m = date.getMonth();
        var y = date.getFullYear();


        if (isScadenziarioPagamenti == 1)
            eventsFullDay = true;



        scadenziario.calendario = $('[data-interaction="scadenziario"]').fullCalendar(
            $.extend({
                eventSources: [
                    {
                        url: app.baseUrl + '/scadenziario?isScadenziarioPagamenti=' + this.isScadenziarioPagamenti,
                        type: 'GET'
                    }
                ],
                //isRTL: true,
                buttonText: {
                    prev: '<',
                    next: '>'
                },

                schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',

                defaultView:  ((isScadenziarioPagamenti) ? 'month' : (preference.get("scadenziario_mode") || 'agendaWeek')),

                header: {
                    left: 'prev,next today',
                    center: 'title',
                    right: 'agendaWeek, month'//,agendaDay'
                },
                events: [],
                editable: true,
                dayClick: function (date, jsEvent, view) {
                    if (scadenziario.isScadenziarioPagamenti == 0)
                        scadenziario.editEvent(date);

                },
                eventClick: function (calEvent, jsEvent, view) {
                    if (scadenziario.isScadenziarioPagamenti == 0)
                        scadenziario.editEvent(undefined, calEvent);
                },
                eventMouseover: function (calEvent, jsEvent, view) {
                    if (scadenziario.isScadenziarioPagamenti == 1) {
                        tooltip = '<div class="tooltiptopicevent" style="width:auto;height:auto;background:#87CEFA;position:absolute;z-index:10001;padding:10px 10px 10px 10px ;  line-height: 200%;">' + calEvent.title + '</div>';

                        $("body").append(tooltip);
                        $(this).mouseover(function (e) {
                            $(this).css('z-index', 10000);
                            $('.tooltiptopicevent').fadeIn('500');
                            $('.tooltiptopicevent').fadeTo('10', 1.9);
                        }).mousemove(function (e) {
                            $('.tooltiptopicevent').css('top', e.pageY + 10);
                            $('.tooltiptopicevent').css('left', e.pageX + 20);
                        });
                    }
                },
                eventMouseout: function (data, event, view) {
                    if (scadenziario.isScadenziarioPagamenti == 1) {
                        $(this).css('z-index', 8);

                        $('.tooltiptopicevent').remove();
                    }

                },
                eventAfterAllRender: function () {
                    $("[data-interaction=scadenziario] .fc-toolbar button").each(function () {
                        if (!$(this).attr("data-refetchBind")) {
                            $(this).attr("data-refetchBind", 1);

                            if (!isScadenziarioPagamenti) {
                                if ($(this).hasClass('fc-agendaWeek-button')) {
                                    $(this).bind("click", function () {
                                        preference.set("scadenziario_mode", "agendaWeek");
                                    });
                                }
                                if ($(this).hasClass('fc-month-button')) {
                                    $(this).bind("click", function () {
                                        preference.set("scadenziario_mode", "month");
                                    });
                                }
                            }
                        }
                    });
                }

            }, fullcalendar_traduzione_italian));

    };

    this.editEvent = function (data, calEvent) {
        calEvent = calEvent || undefined;
        data = data || undefined;

        var colore = undefined;
        var type = undefined;

        if (calEvent) {

            data = calEvent.start;
            eventId = calEvent.id;
            eventTitle = calEvent.title;
            colore = calEvent.color;

            if("type" in calEvent)
                type = calEvent.type;


        } else {
            eventId = 0;
            eventTitle = "";
            colore = "";
        }

        var selDatePicker = data.format('DD/MM/YYYY HH:mm');
        //display a modal
        var modal =
            '<div id="modalScadenziario" class="modal fade">\
              <div class="modal-dialog">\
               <div class="modal-content">\
                 <div class="modal-body">\
                   <button type="button" class="close" data-dismiss="modal" style="margin-top:-10px;">&times;</button>\
                   <form id="frmEventoScadenziario" class=" form-horizontal" data-id="' + eventId + '" action="' + app.baseUrl + "/scadenziario" + '">\
                           <div class="form-group">\
                                <label class="col-sm-2 control-label">Data/Ora &nbsp;</label>\
                                <div class="col-sm-5">\
                                        <input class="datetimepicker form-control" name="data" class="middle form-control" type="text" value="' + selDatePicker + '" />\
								</div>\
						   </div>\
						   <div class="form-group">\
								<label class="col-sm-2 control-label">Titolo evento &nbsp;</label>\
								<div class="col-sm-8">\
									<input class="form-control" name="evento" class="middle form-control" type="text" value="' + eventTitle + '" />\
								</div>\
						   </div>\
						   <div class="form-group">\
								<label class="col-sm-2 control-label">Colore &nbsp;</label>\
								<div class="col-sm-8">\
									<select class="form-control" name="colore">\
									  <option></option>\
									  <option value="red" ' + ((colore == "red") ? "selected" : "") + '>rosso</option>\
									  <option value="orange" ' + ((colore == "orange") ? "selected" : "") + '>arancio</option>\
									  <option value="green" ' + ((colore == "green") ? "selected" : "") + '>verde</option>\
								  </select>\
								</div>\
						   </div>\
						   <hr class="col-xs-10">\
						   <div class="row">\
                               <div class="center col-xs-12">\
                                    <button data-callback="scadenziario_callbackSave" data-hideloader="1" data-interaction="save" data-href="dashboard" type="submit" class="btn btn-xs btn-success"><i class="ace-icon fa fa-check"></i> Salva</button>\
                                    <button data-callback="scadenziario_callbackDelete" data-interaction="delete" type="button" data-reload="1" class="btn btn-xs btn-danger" data-action="delete"><i class="ace-icon fa fa-trash-o"></i> Cancella evento</button>\
                               </div>\
						   </div>\
					   </form>\
					 </div>\
					 <div class="modal-footer">\
						<button type="button" class="btn btn-xs" data-dismiss="modal"><i class="ace-icon fa fa-times"></i> Annulla</button>\
					 </div>\
				  </div>\
				 </div>\
				</div>';


        var modalManutenzione = '<div id="modalScadenziario" class="modal fade">\
              <div class="modal-dialog">\
               <div class="modal-content">\
                 <div class="modal-body">\
                   <button type="button" class="close" data-dismiss="modal" style="margin-top:-10px;">&times;</button>\
                   <form id="frmEventoScadenziario" class=" form-horizontal" data-id="' + eventId + '" action="' + app.baseUrl + "/scadenziario" + '">\
                           <div class="form-group">\
                                <label class="col-sm-2 control-label">Data/Ora &nbsp;</label>\
                                <div class="col-sm-5">\
                                        <input class="datetimepicker form-control" name="data" class="middle form-control" type="text" value="' + selDatePicker + '" />\
								</div>\
						   </div>\
						   <div class="form-group">\
								<label class="col-sm-2 control-label">Titolo evento &nbsp;</label>\
								<div class="col-sm-8">\
									<input class="form-control" name="evento" class="middle form-control" type="text" value="' + eventTitle + '" />\
								</div>\
						   </div>\
						   <hr class="col-xs-10">\
						   <div class="row">\
                               <div class="center col-xs-12">\
                                    <button type="button" data-interaction="save_manutenzione" data-reload="1" data-fe-can-be-hide type="submit" class="btn btn-success btn-xs"> <i class="fa fa-check"></i>Manutenzione effettuata</button>\
                               </div>\
						   </div>\
					   </form>\
					 </div>\
					 <div class="modal-footer">\
						<button type="button" class="btn btn-xs" data-dismiss="modal"><i class="ace-icon fa fa-times"></i> Annulla</button>\
					 </div>\
				  </div>\
				 </div>\
				</div>';


        $("#modalScadenziario").detach();

        if(type==undefined || type!='GPM')
        modal = $(modal).appendTo('body');
        else
            modal = $(modalManutenzione).appendTo('body');


        if (calEvent) {
            modal.find("[data-interaction=delete]").show();
        } else {
            modal.find("[data-interaction=delete]").hide();
        }

        app.runBind();
        var c = new crud({
            form: 'frmEventoScadenziario'
        });

        modal.modal('show').on('hidden', function () {
            modal.remove();
        });

        if(type=="GPM") {
            $('[data-interaction=save_manutenzione]').unbind('click').bind('click', function () {
                $.post(app.baseUrl + '/manutenzione_macchinario/done_manutenzione', {idManutenzione: calEvent.id}, function (data) {
                    if(data.response){
                        $("[data-dismiss=modal]").trigger("click");
                        app.success("Manutenzione effettuata!");
                        app.reload();
                    }else{
                        $("[data-dismiss=modal]").trigger("click");
                        app.error(data.message);
                    }
                });
            });
        }
    };

    this.callbackSave = function () {

    };

    this.callbackDelete = function () {

    };

    this.update = function (calEvent, callback) {
        $.post("?p=scadenziario&a=update", {
            id: calEvent.id,
            titolo: calEvent.titolo,
            colore: calEvent.colore
        }, function (data) {
            if (data.response && (typeof callback == 'function'))
                callback();
        });
    };

    this.insert = function (calEvent, callback) {
        $.post("scadenziario", {
            titolo: calEvent.titolo,
            data: calEvent.data,
            colore: calEvent.colore
        }, function (data) {
            if (data.response && (typeof callback == 'function'))
                callback(data);
        });
    };

    this.elimina = function (calEvent, callback) {
        $.post("?p=scadenziario&a=elimina", {id: calEvent.id}, function (data) {
            if (data.response && (typeof callback == 'function'))
                callback();
        });
    };

    this.showModalPagamento = function (dt) {
        var ids = dt.selected_ids;

        if (!ids || ids.length == 0) {
            app.warning("", "Selezionare almeno un elemento");
            return;
        }
        app.block(1);
        $("#modalPagamento").unbind('shown.bs.modal').bind('shown.bs.modal', function() {
            $("#riepilogo_pagamenti").load(app.baseUrl + "/report_pagamenti?dt=scadenziario_modal&" + $.param({ids: ids}));
            $.post(app.baseUrl + "/documento/get_tipo_pagamento_scadenziario", {id: ids[0]})
                .done(function (data) {
                    if (data.response) {
                        $("#id_tipo_pagamento").val(data.message).trigger('change');
                    } else {
                        app.warning("", data.message);
                    }

                    app.block(0);
                })
                .fail(function (data) {
                    app.block(0);
                    app.error("", app.parseAjaxError(data));
                });
        });
        $("#modalPagamento").modal("show");
    };

    this.bindModalEvents = function () {
        $('[data-interaction=set_pagamento]').unbind('click').bind('click', function () {
            if (confirm("Il totale fatture è uguale al totale incassato?")) {
                var ids = $("#tblReportPagamenti_scadenziario").data('dataTable').selected_ids;
                var data_effettiva = $("#data_effettiva").val();
                var id_tipo_pagamento = $("#id_tipo_pagamento").val();

                app.block(1);
                $.post(app.baseUrl + "/pagamento/set_pagamento", {ids: ids, data_effettiva: data_effettiva, id_tipo_pagamento: id_tipo_pagamento})
                    .done(function (data) {
                        if (data.response) {
                            app.success("", "Pagamenti inseriti correttemente");
                            $("#tblReportPagamenti_scadenziario").data('dataTable').selected_ids = [];
                            $("#tblReportPagamenti_scadenziario").data('dataTable').redrawPage();
                            $("#modalPagamento").modal("hide");
                        } else {
                            app.warning("", data.message);
                        }

                        app.block(0);
                    })
                    .fail(function (data) {
                        app.block(0);
                        app.error("", app.parseAjaxError(data));
                    });
            }
        });
    }

    this.calendario = {};
};
var scadenziario_callbackSave = function () {
    $("#modalScadenziario .close").click();
};
var scadenziario_callbackDelete = function () {
    $("#modalScadenziario .close").click();
};


	
