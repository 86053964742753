var calendar = function (selector) {

    var calendar = this;

    this.objCalendar = selector;
    this.enablePopover = true;

    this.bindEvents = function () {
        calendar.initCalendario();
    };

    this.initCalendario = function () {
        /* initialize the calendar */

        calendar.objCalendar.fullCalendar({
            defaultView: 'timelineWeek',
            editable: true,
            droppable: true,
            slotDuration: "00:30:00",
            minTime: "08:00:00",
            maxTime: "19:00:00",
            header: {
                left: 'prev, next',
                center: 'title',
                right: 'timelineWeek,agendaWeek'
            },
            buttonHtml: {
                prev: '<i class="ace-icon fa fa-chevron-left"></i>',
                next: '<i class="ace-icon fa fa-chevron-right"></i>'
            },
            buttonText: {
                timelineWeek: 'Timeline',
                agendaWeek: 'Agenda',
            },
            resourceLabelText: 'PROGETTO',
            resources:function (callback) {
                var params = {
                    idProgetto: $("#filtro_progetto").val(),
                    idSoggetto: $("#filtro_soggetto").val()
                };

                $.get(app.baseUrl + '/calendario/resources', params, function (data) {
                    calendar.calendar_resources = data;
                    callback(data);
                });
            },
            events: {
                url: app.baseUrl + '/calendario/data',
                type: 'GET',
                data:
                    function () {
                        return {
                            idProgetto: $("#filtro_progetto").val(),
                            idSoggetto: $("#filtro_soggetto").val(),
                            visualizzazione: calendar.objCalendar.fullCalendar('getView').type
                        };
                    },
                error: function () {
                    app.error('Errore recuperando gli eventi del calendario');
                },
            },
            viewRender: function() {
                calendar.objCalendar.fullCalendar('removeEvents');
                calendar.objCalendar.fullCalendar('refetchEvents');
            },
            eventReceive: function(event) {
                if(event.resourceId === null) {
                    calendar.objCalendar.fullCalendar('removeEvents');
                    calendar.objCalendar.fullCalendar('refetchEvents');
                    return;
                }

                $.post(app.baseUrl + "/calendario/crea", {
                    id_subject: event.id,
                    id_risorsa: event.resourceId,
                    start: event.start.format("YYYY-MM-DD HH:mm:ss"),
                    end: event.start.format("YYYY-MM-DD HH:mm:ss")
                })
                .done(function (data) {
                    if(data.response) {
                        calendar.objCalendar.fullCalendar('removeEvents');
                        calendar.objCalendar.fullCalendar('refetchEvents');
                    } else {
                        app.warning("", data.message);
                    }
                })
                .fail(function () {
                    app.error("", "Errore Ajax!");
                });
            },
            eventRender: function (event, element, a) {
                element.find('.fc-content').html(event.html);
            },
            eventDragStart: function () {
                calendar.enablePopover = false;
            },
            eventDragStop: function (data, evento, ui, resourceId) {
                calendar.enablePopover = true;
            },
            eventDrop: function(event, delta, revertFunc, jsEvent, ui, view){
                $.post(app.baseUrl + "/calendario/" + event.id + "/aggiorna", {
                    id_risorsa: event.resourceId,
                    inizio: event.start.format("YYYY-MM-DD HH:mm:ss"),
                    fine: event.end.format("YYYY-MM-DD HH:mm:ss")
                })
                .done(function (data) {
                    if(data.response) {
                        calendar.objCalendar.fullCalendar('removeEvents');
                        calendar.objCalendar.fullCalendar('refetchEvents');
                    } else {
                        app.warning("", data.message);
                    }
                })
                .fail(function () {
                    app.error("", "Errore Ajax!");
                });
            },
            eventResize: function(event, delta, revertFunc){
                $.post(app.baseUrl + "/calendario/" + event.id + "/aggiorna", {
                    id_risorsa: event.resourceId,
                    inizio: event.start.format("YYYY-MM-DD HH:mm:ss"),
                    fine: event.end.format("YYYY-MM-DD HH:mm:ss")
                })
                    .done(function (data) {
                        if(data.response) {
                            calendar.objCalendar.fullCalendar('removeEvents');
                            calendar.objCalendar.fullCalendar('refetchEvents');
                        } else {
                            app.warning("", data.message);
                        }
                    })
                    .fail(function () {
                        app.error("", "Errore Ajax!");
                    });
            },
            eventClick: function (event, jsEvent, view) {
                app.block(1);
                $.get(app.baseUrl + '/calendario/' + event.id + '/modifica')
                    .done(function (data) {
                        app.block(0);

                        $("#modalEdit .modal-body").html(data);

                        $('#modalEdit').modal('show');

                        $('#modalEdit').on('hidden.bs.modal', function () {
                            calendar.objCalendar.fullCalendar('removeEvents');
                            calendar.objCalendar.fullCalendar('removeResources');
                            calendar.objCalendar.fullCalendar('refetchEvents');
                            calendar.objCalendar.fullCalendar('refetchResources');
                        });
                    })
                    .fail(function (data) {
                        app.block(0);
                        app.error("", "Errore AJAX!");
                    });
            }
        });
    };

    calendar.bindEvents();
};
