/**
 * Oggetto utilizzato per gestire il timesheet
 */
var timesheet_giornata = function () {

    var timesheet_giornata = this;

    this.init = function () {

        $('#data_giornata').unbind("change").bind("change", function () {
            app.locationHref('timesheet/giornata?date=' + $('#data_giornata').val() + "&id_subject=" + $('[data-interaction="utente_giornata"]').val());
        });
        $('[data-interaction="utente_giornata"]').unbind("change").bind("change", function () {
            app.locationHref('timesheet/giornata?date=' + $('#data_giornata').val() + "&id_subject=" + $('[data-interaction="utente_giornata"]').val());
        });

        $("[data-interaction=dalle]").each(function (index, obj) {
            $(obj).unbind('focus').bind("focus", function () {
                if ($.trim($(this).val()) != '')
                    return;

                var nRiga = $(this).parents("tr").attr("data-currentrow");
                if ($("[data-currentrow=" + (nRiga - 1) + "]").length > 0)
                    $(this).val($("[data-currentrow=" + (nRiga - 1) + "]").find("[data-interaction=alle]").val());
            });
        });

        $(".time_picker").inputmask("hh:mm");

        $("[data-interaction=progetto]").unbind("change").bind('change', function () {
            var apitoken = $('#api_token').val();
            var objTask = $(this).parents("tr").find("[data-interaction=task]");
            objTask.html("");
            $.get(app.baseUrl + "/api/v1/erp/projects/" + $(this).val() + "/tasks?api_token=" + apitoken, function (data) {
                var results = $.map(data, function (item) {
                    var ret = [];
                    $(item).each(function (index, obj) {
                        var text = obj.title + " (" + obj.effort_utilizzato + "/" + obj.effort_disponibile + ")";
                        ret.push({
                            id: obj.id,
                            text: text,
                            label: text
                        });
                    });
                    return ret;
                });

                $(results).each(function (index, obj) {
                    var option = $("<option value='" + obj.id + "'>" + obj.text + "</option>");
                    objTask.append(option);
                });
            });
        });

        $(".field-timesheet").bind("change", function () {
            $(this).parents("tr[data-id]").attr("data-updated", "1");
        });

        $("[data-interaction='save-top']").unbind("click").bind("click", function () {

            var aData = [];

            $("#timesheet_giornata").find("tr[data-id]").each(function (index, obj) {

                var id = parseInt($(obj).attr("data-id"));
                var deleted = parseInt($(obj).attr("data-deleted"));
                var updated = parseInt($(obj).attr("data-updated"));

                var from = $(obj).find("[data-interaction=dalle]").val() || undefined;
                var to = $(obj).find("[data-interaction=alle]").val() || undefined;

                var riga = {
                    'id': id,
                    'deleted': deleted,
                    'updated': updated,
                    'date': $('#data_giornata').val(),
                    'from': from,
                    'to': to,
                    'id_task': $(obj).find("[data-interaction=task]").val(),
                    'description': $(obj).find("[data-interaction=note]").val()
                };

                if (from && to && ((deleted && id) || updated)) {
                    aData.push(riga);
                }

            });

            var apitoken = $('#api_token').val();
            app.blockUI(true);
            $.post(app.baseUrl + "/api/v1/erp/timesheet/store_giornata?api_token=" + apitoken, {aData: aData}, function (data) {
                app.blockUI(false);

                if (data.response) {
                    app.locationHref('timesheet/giornata?date=' + $('#data_giornata').val() + "&id_subject=" + $('[data-interaction="utente_giornata"]').val());
                } else {
                    app.error(data.message);
                }
            });
        });

        $("[data-interaction=delete_row]").unbind("click").bind("click", function () {
            $(this).parents("tr").attr("data-deleted", 1).attr("data-updated", 1);
        });

        $("[data-interaction=task]").select2();

        $('select.select2_api').each(function () {
            var apitoken = $('#api_token').val();
            var $url = $(this).attr("data-url") + "?api_token=" + apitoken;
            var minimumInputLength = ((typeof $(this).attr("data-length") != 'undefined') ? $(this).attr("data-length") : 1);

            $(this).select2({
                ajax: {
                    url: $url,
                    dataType: 'json',
                    delay: 100,
                    type: 'GET',
                    data: function (params) {
                        return {
                            search: params.term, // search term
                            page: params.page
                        };
                    },
                    processResults: function (data, params) {
                        // parse the results into the format expected by Select2
                        // since we are using custom formatting functions we do not need to
                        // alter the remote JSON data, except to indicate that infinite
                        // scrolling can be used

                        params.page = params.page || 1;
                        return {
                            results: $.map(data, function (item) {
                                var ret = [];
                                $(item).each(function (index, obj) {
                                    ret.push({
                                        id: obj.id,
                                        text: obj.full_name,
                                        label: obj.code + " - " + obj.title
                                    });
                                });
                                return ret;
                            })
                        };

                        // params.page = params.page || 1;
                        //
                        // return {
                        //     results: data.items
                        // };
                    },
                    cache: true
                },
                escapeMarkup: function (markup) {
                    return markup;
                },
                minimumInputLength: minimumInputLength,
                cache: true,
                dropdownAutoWidth: true,
                allowClear: false,
                templateResult: autocomplete_templateResult,
                templateSelection: autocomplete_templateSelection
            }).on("select2:unselecting", function (e) {
                $(this).data('state', 'unselected');
            });
        });

        // template di visualizzazione dei risultati della select2
        function autocomplete_templateResult(data) {
            return data.text;
        }

        // template di visualizzazione del risultato selezionato della select2
        function autocomplete_templateSelection(data) {
            if (typeof data.label == "undefined")
                data.label = data.text;
            return data.label;
        }

    }

};